import styled from "styled-components";
import React, { useEffect } from "react";
import { BottomNavSegment } from "../components/PageSegments/BottomNav";
import { BasicLayout } from "../layouts/BasicLayout";
import SpecialOfferModal from "../components/Modal/SpecialOfferModal";
import {
  SpecialOfferBar,
  HeroSegment,
  AppPitchSegment,
  TestimonySegment,
} from "../components/PageSegments/special-offer/landing";
import { useOnboardingContext } from "../components/PageSegments/onboarding/OnboardingContext";
import { web_acq_landing_page_view } from "../services/mixpanel/acquisition-events";
import { fbq } from "../services/pixel";
import { setMixpanelProperties } from "../services/mixpanel/mixpanel";
// import { REMOTE_CONFIG_STRING_KEYS } from "../services/firebase-remote-config";
// import { Link } from "gatsby"
// import useRemoteConfig from "../hooks/useRemoteConfig";

// const TEST_KEY = REMOTE_CONFIG_STRING_KEYS.landingPageVariation;

export default (data: any) => {
  const { setOnboardingLandingPage } = useOnboardingContext();
  const urlParams = new URLSearchParams(data.location.search);
  const fromParam = urlParams.get("from") ?? undefined;
  //   const [variant, useDefault] = useRemoteConfig(TEST_KEY);

  //   useEffect(() => {
  //     if (variant === "A" || variant === "B") {
  //       console.debug("Welcome variation", variant);
  //       setMixpanelProperties(
  //         { landingPageVariation: variant === "A" ? "control" : "newAnimation" },
  //         true,
  //       );
  //     }
  //   }, [variant]);

  useEffect(() => {
    if (fromParam) {
      localStorage.setItem(
        "utmData",
        JSON.stringify({
          utm_campaign: fromParam,
          utm_channel: fromParam,
          utm_source: fromParam,
          utm_medium: fromParam,
          utm_content: fromParam,
        }),
      );

      setMixpanelProperties({
        "UTM source": fromParam,
        "UTM medium": fromParam,
        "UTM campaign": fromParam,
        "UTM content": fromParam,
        "UTM channel": fromParam,
      });
    }

    web_acq_landing_page_view();
    fbq("trackCustom", "landing_page_view");
    setOnboardingLandingPage("web_discount_flow");
  }, [setOnboardingLandingPage, fromParam]);

  //   if (variant !== "A" && variant !== "B" && !useDefault) {
  //     return null;
  //   }

  return (
    <BasicLayout>
      <Main>
        <>
          <SpecialOfferBar />
          <HeroSegment />
          <TestimonySegment />
          <AppPitchSegment />
          <SpecialOfferModal />
        </>
      </Main>
      <BottomNavSegment />
    </BasicLayout>
  );
};

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
